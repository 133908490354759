import * as THREE from 'three';

import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-human-sprites';
import $ from 'jquery';

let HUD = function() {
	let self = this;
	self.mapGauge = {};
	self.chatGauge = {};
	self.prevRot = 0;
	self.size = 50;
	// X/Y ARE SWAPPED -- TO FIX
	var originX = 10;
	var originY = 200;
	var bgColor = "#FFffffaa";

	self.init = function()
	{
		self.mapGauge = document.getElementById("orientationGauge");
		self.mapGauge.addEventListener("mousedown",self.onMouseDown,false);
		self.mapGauge.addEventListener("mouseover",self.onMouseOver,false);
		self.mapGauge.addEventListener("mouseout",self.onMouseOut,false);
		document.getElementById("numVisitors").style.display = "none" ;


		/********************************************************************/
		//React to the chat panel opening or closing and setting the icon for the map to move accordingly
		document.addEventListener("showChatPanel",function()
		{
			self.mapGauge.style.right = "325px";
		});
		document.addEventListener("hideChatPanel",function(){
			self.mapGauge.style.right = "55px";
		});
		/********************************************************************/
		document.addEventListener("enteringTopCameraView", (event) => {
			self.hideIcon();
		});
		document.addEventListener("exitingTopCameraView", (event) => {
			self.showIcon();
		});
		/********************************************************************/
	};
	self.onMouseDown = function()
	{
		if(!document.wb.isInTopCameraView)
		{
			document.wb.animateCameraToTopView();
		}
	}
	self.onMouseOver = function()
	{
		if(!document.wb.isInTopCameraView)
		{
			self.mapGauge.style.cursor = "alias;";
		}
		else
		{
			self.mapGauge.style.cursor = "default;";
		}
	}
	self.onMouseOut = function()
	{
		self.mapGauge.style.cursor = "default;";
	}
	self.hideIcon = function()
	{
		self.mapGauge.style.right = "-60px";
		window.setTimeout(function(){

			// console.log(document.HUD.htmlElement.style.right,self.mapGauge.style.right);
		},1000);
	};
	self.showIcon = function(){
		// console.log("show icon");
		self.mapGauge.style.right = "55px";
	};
	self.animate = function()
	{

		setTimeout(function () {
			requestAnimationFrame(self.animate);
		}, 1000 / 26);
		 bgColor = "#FFffffaa";
		if(document.wb.isInTopCameraView)
		{
			 bgColor = "#fafafa";
			 self.canvas.style = "cursor:default;";
		}
		if( Math.abs(self.prevRot - document.wb.camera.rotation.y) > 0 )
		{
			self.prevRot= document.wb.camera.rotation.y;

			var ctx = self.canvas.getContext("2d");

			ctx.fillStyle = bgColor;
			ctx.lineWidth = "1";
			ctx.strokeStyle = "#FFFFFFFF";
	        ctx.arc(self.size, self.size/2+5, self.size/2, 0,2*Math.PI, true);
	        ctx.stroke();
	        ctx.fill();

			ctx.fillStyle = "#FFffffff";

			let deltaX = originX -document.wb.camera.position.z;
			let deltaY = originY -document.wb.camera.position.x; // reverse
			let radiansFromCenter = Math.atan2(deltaY, deltaX)
			let rotationTmp = document.wb.camera.rotation.y-Math.PI;
			var radius = 20;

			ctx.save();
			ctx.beginPath();
			ctx.lineWidth = "2";
			ctx.strokeStyle = "#ffaaaa";

			ctx.translate(self.size, self.size/2+5);
			ctx.rotate(rotationTmp);
			var unit = (self.size/2) - 10;
			ctx.moveTo(unit,0);
			ctx.lineTo(- (unit*0.2),unit*0.35);
			ctx.lineTo( (unit*0.1),0);
			ctx.lineTo(- (unit*0.2),-unit*0.35);
			ctx.lineTo(unit, 0);
			ctx.closePath();
			ctx.stroke();

			ctx.restore();

			ctx.beginPath();
			ctx.strokeStyle = "#aaaaff";
			ctx.fillStyle =  "#aaaaff";
			ctx.lineWidth = "0.5";
			ctx.arc(self.size+Math.cos(radiansFromCenter)*radius,(self.size/2+5)+Math.sin(radiansFromCenter)*radius, 4,0,2*Math.PI, true );
			ctx.closePath();
			ctx.fill();
			ctx.stroke();

		}
	};
	self.updateVisitorsCount = function(newCount)
	{

		if(newCount>1 && document.getElementById("numVisitors").style.display == "none")
		{
			document.getElementById("numVisitors").style.display = "block";

		}
		else if(newCount <= 1 && document.getElementById("numVisitors").style.display == "block")
		{
			document.getElementById("numVisitors").style.display = "none";
		}
		if(newCount>99)
		{
			document.getElementById("numVisitors").style.width = "35px"
		}
		else {
			document.getElementById("numVisitors").style.width = "25px"
		}
		document.getElementById("numVisitors").innerText = ""+newCount;
	}
	self.updateAvatar = function(newName)
	{
		let options = {width:25,height:25};
		let avatars = new Avatars(sprites, options);
		let svg = avatars.create(newName);
		let avatarBox = document.getElementById("avatarBox");
		avatarBox.innerHTML = svg;
	}
};


export {HUD};
