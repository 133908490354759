
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import {
	Euler,
	EventDispatcher,
	Vector3
} from "./three.module.js";



var WallWithNames = function () {

	let self = this;
	self.url = "";
	self.x = -1;
	self.y = -1;
	self.z = -1;
	self.obj;

	self.create = function (position, callback) {

		//ADD POSITION
		self.x = position.x * 20;
		self.y = position.y * 20 + 10;
		self.z = position.z * 20;

		
		//LOAD IMAGE
		// self.url = "./shared/ExportUVLayoutNames.png";
		// var texMaterial;
		// var texture = new THREE.TextureLoader().load(self.url);
		// texMaterial = new THREE.MeshBasicMaterial({ map: texture });

		//LOAD 3D model
		var loader = new GLTFLoader();
		let finishLoadingCallback = callback;

		loader.load( '/shared/Title.glb', function ( gltf ) {


			let objTmp = new THREE.Object3D();

			objTmp.scale.set(4.5, 4.5, 4.5);
			objTmp.rotation.set(-Math.PI / 2, 0, 0);
			objTmp.position.set(self.x, self.y, self.z);

			objTmp.add(gltf.scene.children[0]);
			finishLoadingCallback(objTmp);

			self.obj = objTmp;

		}, undefined, function (error) {

			console.error("error", error);

		});


	}

	self.animate = function () {
		//MAKE IT MOVE

		//STOP IF NEAR USER


	}

}

// Frame.prototype = Object.create( EventDispatcher.prototype );
// Frame.prototype.constructor = WorldBuilder;
export { WallWithNames }