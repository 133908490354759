import * as THREE from 'three';
import { GLTFLoader } from '../shared/CustomGLTFLoader.js';
import $ 							from 'jquery';
import svgToImage from 'svg-to-image';

import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-human-sprites';

// Player class
var Player = function(props) {
	let self = this;

	self.clientID = -1;
	// These are the props for the Player, like name, position, id, etc.

	Object.assign(self, props) // Extend this with the props

	// Some helpers to get a unique color value based on ID
	self.clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
	self.invlerp = (x, y, a) => this.clamp((a - x) / (y - x));


	self.geometry = new THREE.Object3D();
	self.faceTexture = new Image();
	self.didLoad = false;

	//Generate face based on Name
	if(!props.isMyself)
	{
		//Generate Avatar
		let options = {width:32,height:32};
		let avatars = new Avatars(sprites, options);
		let svg = avatars.create(props.name);

		//Get image from Canvas
		self.funnyTexture = new THREE.Texture(self.canvas);
		self.funnyTexture.needsUpdate = true;

		svgToImage(svg, {
		  crossOrigin: 'Anonymous'
		}, function (err, image) {
		  if (err) {
		    // there was a problem rendering the SVG data
		    throw new Error('could rasterize SVG: ' + err.message);
		  }
		  // no error; update the WebGL texture
		  self.funnyTexture.image = image;
		  self.funnyTexture.needsUpdate = true;
		});
	}

	self.funnyMaterial = new THREE.MeshBasicMaterial({
    map: self.funnyTexture
	});

	//START LOADING THE AVATAR BOX
	var loaderThreeD = new GLTFLoader();

	loaderThreeD.load( 'https://museumofinteractivity.github.io/moi-src/3dModels/avatarBox.glb', function ( gltf ) {

		let objTmp = new THREE.Object3D( );

		//Material
		if(gltf.scene.children[0].children[1].material != undefined)
		{
			gltf.scene.children[0].children[1].material = self.funnyMaterial;
			gltf.scene.children[0].children[1].material.needsUpdate = true;
		}
		if(gltf.scene.children[0].children[0].material != undefined)
		{
			const diffuseColor = new THREE.Color();
			//0xE3004E #EA6749 #F2DE6F #A20B6B
			const arrCol = [0xE3004E,0xEA6749,0xF2DE6F,0xA20B6B];

			const materialTmp = new THREE.MeshToonMaterial( {
				color: arrCol[ Math.round(Math.random()*3) ]
			} );
			gltf.scene.children[0].children[0].material = materialTmp;
			gltf.scene.children[0].children[0].material.needsUpdate = true;
		}
		gltf.scene.children[0].children[0].castShadow = true;
		objTmp.add( gltf.scene.children[0]);
		objTmp.scale.set	(2.0,2.0,2.0);

		objTmp.rotation.set	(0,0,0);
		objTmp.position.set	(0,-1.0,0);

		self.geometry.add(objTmp);

		//Load font
		var loader = new THREE.FontLoader();

		loader.load( '/shared/droid_sans_regular.json', function ( font ) {
			self.font = font;

			///NAME · ADD NAME MESH
			var color = 0x006699;
			var matLite = new THREE.MeshBasicMaterial( {
				color: color,
				transparent: true,
				opacity:0.6,
				side: THREE.DoubleSide
			} );
			if(self.name == undefined)
			{
				self.name = "";
			}
			var shapes = font.generateShapes( self.name, 1 );

			var geomText = new THREE.ShapeBufferGeometry( shapes );

			geomText.computeBoundingBox();

			let text = new THREE.Mesh( geomText, matLite );
			text.position.y = +2;
			text.rotation.set	(0,Math.PI/2,0);
			// text.position.z = - 150;
			self.nameGeometry = text;
			self.geometry.add( self.nameGeometry );


			self.geometry.elementsNeedUpdate = true;
			self.geometry.verticesNeedUpdate = true;

			try {
				self.geometry.position.set(self.x, self.y, self.z)
				self.didLoad = true;
			} catch (error) {
				console.log("error moving geometry player",error);
			}
		});
	}, undefined, function ( error ) {
		console.error( "error loading geometry player",error );

	} );

	self.assignClientID = function(clientID)
	{
		self.clientID = clientID;
	}

	self.addName = function(newName,font)
	{
		self.removeName();

		self.name = newName;

		///NAME · ADD NAME MESH
		var color = 0x006699;
		var matLite = new THREE.MeshBasicMaterial( {
			color: color
			// transparent: false,
			// side: THREE.DoubleSide
		} );

		var shapes = font.generateShapes( newName, 100 );

		var geomText = new THREE.ShapeBufferGeometry( shapes );

		geomText.computeBoundingBox();

		// var xMid = - 0.5 * ( geomText.boundingBox.max.x - geomText.boundingBox.min.x );
		var box2 = new THREE.BoxGeometry(0.1, 2.0, 0.1);
		let box2Mesh = new THREE.Mesh(box2,matLite);
		self.geometry.add( box2);

		let text = new THREE.Mesh( geomText, matLite );
		// text.position.z = - 150;
		self.nameGeometry = text;
		self.geometry.add( this.nameGeometry );
	}
	self.removeName = function()
	{
		self.name = undefined;
		self.geometry.remove(this.nameGeometry);
	}
	self.setPosition = function(pos) {
		self.position = pos;
		if(!self.didLoad)
		{
			return;
		}
		try {
			var offset = 0;
			if(self.isMyself)
			{
				offset = 1;
			}
			if(self.geometry != undefined)
			{
				self.geometry.position.set(pos.x, pos.y+offset, pos.z)
			}
		}
		catch (error) {
			console.log("fail to set position");
		}
	}
	self.setRotation = function(rot){
		if(rot != undefined && !isNaN(rot) && !self.isMyself)
		{

			if(self.geometry.children.length == 2)
			{
				self.geometry.children[0].rotation.set(0,rot+(Math.PI/2),0);
				self.geometry.children[1].rotation.set(0,rot-(Math.PI),0);
			}
		}
	}
	return self;
};

export { Player }
