import * as THREE from 'three';
import { Player } from './Player.js'
import { FriendlyWebSocket } from '../wsConnection.js';

class PlayersManager {

	constructor(props) {
		Object.assign(this, props)
		this.visitors = {};
		this.prevPosition;
		this.updatePosThreshold = 10;
		this.rotThreshold = Math.PI/30;
		this.positionUpdated = false;
		this.prevRotation = 0;

		self = this;

		//Init Networking - WebSocket Manager
		if(document.wb.wsm == undefined)
		{
			console.warn("Player manager because WS not yet started.");
			return;
		}
		document.wb.wsm.subscribePlayersUpdateListener(function(list){
			if(self.visitors == undefined)
			{
				return;
			}
			for(var key in list)
			{
				if(key in self.visitors)
				{
					self.visitors[key].setPosition(list[key]);
					self.visitors[key].setRotation(list[key]["rot"]);

					if(self.visitors[key].name != list[key].name)
					{
						//update NAME
						self.visitors[key].name = list[key].name;
					}
				}
				else
				{
					//CREATE NEW PLAYER
					// console.log("create player ["+key+"]:",list[key].name,list[key]);

					if(self.myself.clientID != key && list[key].name != undefined )
					{
					//	console.log("creating player ["+key+"]:",list[key].name);
						self.visitors[key] = new Player({"isMyself":false,"name":list[key].name,"clientID":key-0});
						self.visitors[key].x = list[key].x;
						self.visitors[key].y = list[key].y;
						self.visitors[key].z = list[key].z;
				 		document.wb.scene.add(self.visitors[key].geometry);
						//console.log("added visitor:"+Object.keys(self.visitors).length);
						document.HUD.updateVisitorsCount(Object.keys(self.visitors).length);

			 		}
				}
			}
			for(var key in self.visitors)
			{
				if(!(key in list))
				{
					if(key != self.myself.clientID)
					{

						if(self.visitors[key] != null)
						{

							document.wb.scene.remove(self.visitors[key].geometry);
							self.visitors[key] = null;
						}
						delete self.visitors[key];
						console.log("deleteVisit:"+Object.keys(self.visitors).length);
						document.HUD.updateVisitorsCount(Object.keys(self.visitors).length);
					}
				}
			}

			document.wb.needsToUpdateVisitors = true;
		});
		document.wb.wsm.subscribeClienIDReceived(function(clientID){
			self.myself.clientID = clientID;
		});


		document.wb.wsm.subscribeVisitorConnected(function(clientID){
			console.log("visitorConnected:",clientID);
			if(self.visitors == undefined)
			{
				self.visitors = {};
			}

		});
		document.wb.wsm.subscribeVisitorDisconnected(function(clientID){
			if(self.visitors[clientID] != undefined)
			{
				document.wb.scene.remove(self.visitors[clientID].geometry);
				self.visitors[clientID] = null;
				delete self.visitors[clientID];
				document.HUD.updateVisitorsCount(Object.keys(self.visitors).length);
			}
		});
	}

	updateMyPlayer = () => {

		if(this.prevPosition == undefined)
		{
			this.prevPosition = self.myself.position;
		}

		if(this.magnitude(self.myself.position, this.prevPosition)>this.updatePosThreshold)
		{

			this.prevPosition.x = this.myself.position.x;
			this.prevPosition.y = this.myself.position.y;
			this.prevPosition.z = this.myself.position.z;

			var idTmp = this.myself.clientID;
			if(idTmp == -1)
			{
				return;
			}
			if(!(this.myself.clientID in this.visitors))
			{
				this.visitors[idTmp] = this.myself;
			}

			this.visitors[idTmp].setPosition({"x":document.wb.camera.position.x,"y":document.wb.camera.position.y,"z":document.wb.camera.position.z});
			this.positionUpdated = true;
		}

		if(Math.abs(document.wb.camera.rotation.y - this.prevRotation) > this.rotThreshold)
		{
			this.prevRotation = document.wb.camera.rotation.y;
			this.positionUpdated = true;
		}
		if(this.positionUpdated)
		{
			document.wb.wsm.updatePlayerPosition(this.myself);
			this.positionUpdated = false;
		}

	}
	magnitude = (pos1,pos2) =>{
		return Math.abs(pos1.x-pos2.x)+Math.abs(pos1.y-pos2.y)+Math.abs(pos1.z-pos2.z);
	}
}

export { PlayersManager };
