import $ from 'jquery';


let ChatRoom = function()
{
	let self = this;
	/*CHAT SHOW/HIDE*/
	self.isChatOut = true;

	self.chatGauge = $("#chatGauge");
	self.chatGauge.on("mousedown",function(){
		if(self.isChatOut)
		{
			self.hideChat();
		}
		else {
			self.showChat();
		}
	});
	self.chatGauge.on("over",function(){

	});
	self.chatGauge.on("out",function(){

	});



	let chatRoomObject = this;
	chatRoomObject.messages  = [];
	chatRoomObject.inputChatBox 		= $("#inputChatBox");
	chatRoomObject.responsesContainer = $("#chatResponsesContainer");
	//This line serves to make the response container as tall as the container box
	chatRoomObject.responsesContainer.css("min-height",Math.floor((window.innerHeight*0.92))+"px");
	// self.colors = ["#87C7D5","#F1F0E8","#EE9495","#FCC758","#FFE45B"];
	chatRoomObject.colors = ["#EECDD1","#4CC6DD","#F9E6A7","#CFE2E3"]
	chatRoomObject.colorIndex = 0;
if(document.wb.wsm == undefined)
{
	console.warn("Chat not initiated because webSocket not yet started.");
	return;
}
	document.wb.wsm.subscribeChatListener(function(newMessage){

		var divResponse = $("<div class=\"chatResponse\">"+newMessage.value+"</div><br>");
		// divResponse.css("background-color",  chatRoomObject.colors[chatRoomObject.colorIndex]);
		// would be nice to animate the entrace, but for another time ==> divResponse.css({"background-color":  chatRoomObject.colors[chatRoomObject.colorIndex],"margin-left":"200px"});

		chatRoomObject.colorIndex ++;
		chatRoomObject.colorIndex = chatRoomObject.colorIndex % chatRoomObject.colors.length;
		chatRoomObject.messages = [];
				//ADD lAST MESSAGE
		chatRoomObject.responsesContainer.append(divResponse);

		//REMOVE IF OVERFLOWN
		while(isOverflown(chatRoomObject.responsesContainer[0]))
		{

			chatRoomObject.responsesContainer.children().first().remove();
		}

		for(var index = 0 ; index < chatRoomObject.responsesContainer.children().length;index++)
		{
			if(index < Math.floor(chatRoomObject.responsesContainer.children().length * 0.25) )
			{
				//chatRoomObject.responsesContainer.children()[index].style.opacity = ((index/chatRoomObject.responsesContainer.children().length)*0.65)+0.35;
					chatRoomObject.responsesContainer.children()[index].style.opacity = (((index * 4)/chatRoomObject.responsesContainer.children().length))+0.3;
			}
			else
			{
				chatRoomObject.responsesContainer.children()[index].style.opacity = 1.0;
			}
		}
	});
	function isOverflown(element) {
	  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
	}
	chatRoomObject.inputChatBox.on("keypress",function(e){
		if(e && e.keyCode == 13) {
			if(chatRoomObject.inputChatBox == undefined)
			{
				chatRoomObject.inputChatBox = $("#inputChatBox");
			}
			if(chatRoomObject.inputChatBox[0].value != "")
			{
      			document.wb.wsm.sendChatMessage(document.wb.pm.myself.name+": "+chatRoomObject.inputChatBox[0].value);
   				chatRoomObject.inputChatBox[0].value = "";
   			}
   		}
	})

	self.hideChat = function()
	{
		self.isChatOut = false;
		let chatBox = document.getElementById("chatBox");
    let rect = chatBox.getBoundingClientRect();
		$(chatBox).css("right",-rect.width+"px");
		$("#chatGaugeChatIcon").show();
		$("#chatGaugeCloseIcon").hide();
			document.dispatchEvent(new Event('hideChatPanel', {}));
	}
	self.showChat = function()
	{
		self.isChatOut = true;
		$("#chatBox").css("right","0px");
		$("#chatGaugeChatIcon").hide();
		$("#chatGaugeCloseIcon").show();
			document.dispatchEvent(new Event('showChatPanel', {}));
	}
	self.hideIcon = function()
	{
		$("#chatGauge").css("right","-60px");
	}
	self.showIcon = function()
	{
		$("#chatGauge").css("right","10px");
	}

	//IF CONNECTED ENABLE CHAT

	//OTHERWISE DISABLE CHAT

	//METHODS TO DISABLE/ENABLE CHAT
	self.enableChat = function(){

	}
	self.disableChat = function(){

	}
	document.addEventListener("enteringTopCameraView", (event) => {
			console.log("ENTERING");
			//HIDE CHAT, if chat is out
			self.wasChatOut = self.isChatOut;
			if(self.wasChatOut)
			{
				self.hideChat();

			}
				self.hideIcon();
		});
	document.addEventListener("exitingTopCameraView", (event) => {
		  console.log("EXITING");
			//SHOW CHAT, if chat is out
			if(self.wasChatOut)
			{
				self.showChat();

			}
			self.showIcon();
		});


	return self;
}

export {ChatRoom}
