//V1.0 ---- museumofinteractivity
import * as THREE 					from 'three';
import $ 							from 'jquery';
import { Frame } 					from './frame.js';
import { PointerLockControls } 		from './PointerLockControls.js';
import {
	Euler,
	EventDispatcher,
	Vector3
} from "./three.module.js";
 // import { CSS3DRenderer, CSS3DObject } from 'three';

import { UIModel } 					from "./UIModel.js";
import { WallWithNames } 		from "./WallWithNames";
import { Hallway } 					from "./Hallway";
import { CIIDExhibit, CIIDTitle, CIIDSignage, CIIDLogo } from "./CIIDMockUp.js";
import { Player } 					from './Player.js';
import { PlayersManager } 	from './PlayersManager'
import { CompressedPixelFormat } 	from 'three';
import { RoomBuilder } 			from './RoomBuilder';
import { WSManager } 				from "./WSManager.js";
import { SoundTrack } 			from "./PositionalAudio.js"




var WorldBuilder = function () {



	let self = this;

	// //NETWORK
	self.wsm = new WSManager();
	self.openingMessageSent = false;

	// Create PlayersManager
	self.pm;

	//SCENE
	self.scene;
	self.camera = undefined;
	self.light = undefined;
	var renderer;
	self.controls = {};

	self.meshes = [];


	self.artifacts = [];
	self.floor = undefined;

	self.showRelatedContent = false;

	let font = {};
	self.font = font;
	var loader = new THREE.FontLoader();
	loader.load( '/shared/droid_sans_regular.json', function ( font ) {
		self.font = font;
	});

	var raycaster, wallCollisionRayCaster;

	//INTERACTION WITH MOUSE
	self.doesIntersect 			= {};
	self.cameraIsMoving 		= false;
	self.cameraTarget   		= {};
	self.cameraRotationTarget 	= Math.PI;
	self.invalidateClick		= false;
	self.timeMousePressed		= 0;
	let INTERSECTED				= null;
	var movementFactor          = 0.0;
	var startQuaternion 		= new THREE.Quaternion();
	var endQuaternion 			= new THREE.Quaternion();
	var startCameraPos			= new THREE.Vector3();

	//INTERACTION WITH SEARCH BOX
	var donutSelect 			= {};

	//LOOKAT Strategy
	var movingFakeTarget		= new THREE.Vector3();
	var startMovingFakeTarget   = new THREE.Vector3();

	//INTERACTION AWSD
	var moveForward 	= false;
	var moveBackward 	= false;
	var moveLeft 			= false;
	var moveRight 		= false;
	var canJump 			= false;

	var prevTime 		= performance.now();
	var velocity 		= new THREE.Vector3();
	var direction 	= new THREE.Vector3();
	var vertex 			= new THREE.Vector3();
	var color 			= new THREE.Color();

	// VISITORS

	self.needsToUpdateVisitors = false;

	//MAP DESCRIPTION
	var map;
	self.hoverOverArtifact = undefined;

	//TOP CAMERA
	self.isInTopCameraView = false;


	self.askForName = function(){
		var result = window.prompt("Please type your name:", "");
		if(result!="" && result!=undefined)
		{
			result = result.slice(0,15);
			window.localStorage.setItem("myname",result);
			document.HUD.updateAvatar(result);
			return result;
		}
		else
		{
			self.myself.removeName();
		}
	}


	self.init = function (newMap, newArtifacts) {
		map = newMap[0];

		//RENDERER
		renderer = new THREE.WebGLRenderer({ antialias: true });
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.autoClear = false;

		//SHADOW
		renderer.shadowMap.enabled = true;
		renderer.shadowMap.type = THREE.PCFShadowMap;

		//ADD RENDERER TO HTML
		document.body.appendChild(renderer.domElement);

		//Add blur at the beginning
		$("canvas").addClass("blurred");


		/* CAMERA INIT */
		self.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000);

		self.camera.position.y = 10;
		self.camera.position.x = 200;
		self.camera.position.z = -100;
		self.camera.rotateY(Math.PI);
		self.xplayerPos = self.camera.position;
		self.cameraTarget = self.camera.position;

		//Changing the order makes it easier to read the Euler values
		self.camera.rotation.order = 'YXZ';

		/* SCENE INIT */
		self.scene = new THREE.Scene();
		self.scene.background = new THREE.Color(UIModel.bgColor);
		self.scene.fog = new THREE.Fog(UIModel.bgColor, 0, UIModel.fogDist);
		self.scene.add(self.camera);

		/* ADD ROOMS */
		self.roomBuilder = new RoomBuilder(newArtifacts,function(objTmp){
			self.scene.add(objTmp);



		});

		/* LIGHT */
		//LIGHT FOR SHADOWS
		self.light = new THREE.DirectionalLight(0xfafafa, 0.55);
		self.light.position.set( 0, 50, 0 );
		self.light.castShadow = true;
		const d = 1000;
		self.light.shadow.camera.left 	=  self.roomBuilder.globalBoundaries.xMin;
		self.light.shadow.camera.right 	=  self.roomBuilder.globalBoundaries.xMax;
		self.light.shadow.camera.bottom = - self.roomBuilder.globalBoundaries.yMin;
		self.light.shadow.camera.top 		= - self.roomBuilder.globalBoundaries.yMax;

		self.scene.add( self.light );
		// self.scene.add( new THREE.CameraHelper( self.light.shadow.camera ) );

		//LIGHT WITHOUT SHADOW
		var light2 = new THREE.DirectionalLight(0xfafafa, 0.75);
		light2.position.set( 0, 50, 0 );
		light2.target.position.set(50,25,50);
		self.scene.add( light2 );
		self.scene.add( light2.target );

		var light3 = new THREE.DirectionalLight(0xfafafa, 0.75);
		light3.position.set( 0, 50, 0 );
		light3.target.position.set(-50,25,-50);
		self.scene.add( light3 );
		self.scene.add( light3.target );

		//Create a sphere that cast shadows (but does not receive them)

		const sphereGeometry = new THREE.SphereGeometry( 5, 32, 32 );
		const sphereMaterial = new THREE.MeshStandardMaterial( { color: 0xff0000 } );
		const sphere = new THREE.Mesh( sphereGeometry, sphereMaterial );
		sphere.position.set(-1100,10,0);
		sphere.castShadow = true; //default is false
		sphere.receiveShadow = false; //default
		self.scene.add( sphere );





		self.controls = new PointerLockControls(self.camera, document.body);

		var blocker 		= document.getElementById('blocker');
		var instructions 	= document.getElementById('instructions');
		var closeButton 	= document.getElementById('closeButton');
		var goToMuseumFloor = document.getElementById('goToGallery');

		var goToGalleryLogic = function () {

			if(self.pm == undefined)
			{
				self.pm = new PlayersManager();
			}

			var result = window.localStorage.getItem("myname");
			if(result == undefined)
			{
				result = self.askForName();
			}
			if(self.myself == undefined)
			{
				document.HUD.updateAvatar(result);
				self.pm.myself = new Player({"isMyself":true,"name":result});
				self.pm.myself.position = new THREE.Vector3();
			}
			if(self.wsm != undefined && !self.openingMessageSent)
			{
					self.wsm.sendOpenConnectionMessage({"name":self.pm.myself.name});
					self.openingMessageSent = true;
			}
			self.lockControls();

			//PLAY VIDEO STAGE
			if(self.video !=undefined)
			{
				self.video.play();
			}
			$("canvas").removeClass("blurred");
		};

		closeButton.addEventListener('click', goToGalleryLogic , false);
		goToMuseumFloor.addEventListener('click', goToGalleryLogic , false);

		self.lockControls = function () {
			velocity.x = 0;
			velocity.y = 0;
			velocity.z = 0;
			instructions.style.display = 'none';
			blocker.style.display = 'none';
			$("#moiContainer").show();
			$("#relatedMediaContainer").hide();
			$("#relatedMediaContainer").html("");
			// self.startAudio();
			self.showRelatedContent = false;


		}
		self.unlockControls = function () {
			self.showRelatedContent = true;
			blocker.style.display = 'block';
			instructions.style.display = '';
			self.stopAudio();
			$("canvas").addClass("blurred");
			$("#hoverOverArtifact").hide();
		}

		self.controls.addEventListener('lock', self.lockControls);

		self.controls.addEventListener('unlock', self.unlockControls);

		self.scene.add(self.controls.getObject());

		var onKeyDown = function (event) {

			switch (event.keyCode) {

				case 38: // up
				case 87: // w
				moveForward = true;
				break;

				case 37: // left
				case 65: // a
				moveLeft = true;
				break;

				case 40: // down
				case 83: // s
				moveBackward = true;
				break;

				case 39: // right
				case 68: // d
				moveRight = true;
				break;

				case 32: // space
				if (canJump) {
					velocity.y += 200;
					canJump = false;
				}
				break;
				case 27:
				if(blocker.style.display == "none")
				{
					self.unlockControls();
				}


				break;
			}
		};

		var onKeyUp = function (event) {
			switch (event.keyCode) {

				case 38: // up
				case 87: // w
				moveForward = false;
				break;

				case 37: // left
				case 65: // a
				moveLeft = false;
				break;

				case 40: // down
				case 83: // s
				moveBackward = false;
				break;

				case 39: // right
				case 68: // d
				moveRight = false;
				break;
				case 32:
					//SHOW RELATED CONTENT
					//self.showRelatedContent = true;
					break;
				}
			};

			document.addEventListener('keydown', onKeyDown, false);
			document.addEventListener('keyup', onKeyUp, false);

			raycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, - 1, 0), 0, 10);

			initFloor();

			initDonutSelect();

		// objects
		var boxGeometry = new THREE.BoxBufferGeometry(20, 20, 20);
		boxGeometry = boxGeometry.toNonIndexed(); // ensure each face has unique vertices

		var position = boxGeometry.attributes.position;
		var colors = [];

		for (var i = 0, l = position.count; i < l; i++) {
			color.setHSL(Math.random() * 0.3 + 0.5, 0.75, Math.random() * 0.25 + 0.75);
			colors.push(color.r, color.g, color.b);
		}

		boxGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));


		for (var newBox in map) {
			var boxMaterial;

			boxMaterial = new THREE.MeshPhongMaterial({ specular: 0xffffff, flatShading: true, vertexColors: true });
			// boxMaterial = new THREE.MeshBasicMaterial();
			boxMaterial.color.setHSL(Math.random() * 0.2 + 0.5, 0.75, Math.random() * 0.25 + 0.75);


			var box = new THREE.Mesh(boxGeometry, boxMaterial);
			box.position.x = map[newBox].x * 20;
			box.position.y = map[newBox].y * 20 + 10;
			box.position.z = map[newBox].z * 20;

			self.scene.add(box);

		}

		for (var id in newArtifacts) {
			var frameTmp = new Frame();
			frameTmp.create(newArtifacts[id], function (newObj) {
				newObj.name = frameTmp.name;

				newObj.castShadow = true;
				newObj.receiveShadow = false;

				self.scene.add(newObj);
				if(newObj.children[1] != undefined)
				{
					self.meshes.push(newObj.children[1]);
				}
				else if(newObj.children[0] != undefined)
				{
					self.meshes.push(newObj.children[0]);
				}
			});

			if (newArtifacts[id].linkToOtherArtifacts) {
				for (var i = 0; i < newArtifacts[id].linkToOtherArtifacts.length; i++) {



					var otherArtifact = self.findArtifactWithName(newArtifacts[id].linkToOtherArtifacts[i], newArtifacts);
					var curve = self.createCurveBetweenTwoPoints(frameTmp.convertVector(newArtifacts[id].position), frameTmp.convertVector(otherArtifact.position));

					curve.name = "link"+otherArtifact.title+"-"+newArtifacts[id].title;
					self.scene.add(curve);
				}
			}
			self.artifacts.push(frameTmp);
		}



		loadArchitecture();
		initInteractionWithMouse();

		window.addEventListener('resize', onWindowResize, false);

		self.hoverOverArtifact = $('#hoverOverArtifact');


		///INIT PLAYER
		self.initStageVideo();

		/// INIT AUDIO
		self.startAudio = function () {
			/* AUDIO */

			if(self.listener == undefined)
			{
					self.listener = new THREE.AudioListener();
					self.camera.add( self.listener );

					self.soundTrack = new SoundTrack(self.listener,{x:23,y:0.1,z:13},function(mesh){
						self.scene.add(mesh);
					});
				}else {
					self.soundTrack.resume();
				}

				// var listener = new THREE.AudioListener();
				// self.camera.add(listener);
				// audioElement = document.getElementById('music');
				// var positionalAudio = new THREE.PositionalAudio(listener);
				// positionalAudio.setMediaElementSource(audioElement);
				// positionalAudio.setRefDistance(100);
				// positionalAudio.setDirectionalCone(180, 230, 0.1);

		}
		self.stopAudio = function () {
			if(self.soundTrack != undefined)
			{
				self.soundTrack.pause();
			}
		}
		// self.startAudio();
		/*
		 END INIT
		 */
	}
	let onWindowResize = function() {
		self.camera.aspect = window.innerWidth / window.innerHeight;
		self.camera.updateProjectionMatrix();
		renderer.setSize(window.innerWidth, window.innerHeight);
	}
	let initDonutSelect = function()
	{
		const geometry = new THREE.TorusGeometry( 15, 2, 2, 100 );
		const material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
		donutSelect = new THREE.Mesh( geometry, material );
		donutSelect.rotation.x = Math.PI/2;
		donutSelect.position.y = -20;
		self.scene.add( donutSelect );
	}
	self.showDonutSelect = function(x,y,z){
		donutSelect.position.x = x;
		donutSelect.position.y = y;
		donutSelect.position.z = z;
	}
	self.hideDonutSelect = function()
	{
		donutSelect.position.y = -20;
	}
	let initFloor = function(){

		// floor
		var floorGeometry = new THREE.PlaneBufferGeometry(2000, 2000, 100, 100);
		floorGeometry.rotateX(- Math.PI / 2);

		// vertex displacement
		var position = floorGeometry.attributes.position;

		floorGeometry = floorGeometry.toNonIndexed(); // ensure each face has unique vertices

		position = floorGeometry.attributes.position;
		var colors = [];

		for (var i = 0, l = position.count; i < l; i++) {
			color.setHSL(Math.random() * 0.3 + 0.5, 0.75, Math.random() * 0.25 + 0.75);
			colors.push(color.r, color.g, color.b);
		}

		floorGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));


		// var floorMaterial = new THREE.MeshBasicMaterial({ color: 0xEECDD1 });
		// var floorMaterial = new THREE.MeshBasicMaterial({ color: 0xEECDD1 });
		var floorMaterial = new THREE.MeshLambertMaterial({color: 0xffffff,wireframe: false});

		self.floor = new THREE.Mesh(floorGeometry, floorMaterial);
		// self.floor.material.color = 0xffffff;//(UIModel.bgColor);
		self.floor.name = "floor";

		self.floor.castShadow = false;
		self.floor.receiveShadow = true;

		self.scene.add(self.floor);

	}
	//INTERACTION WITH MOUSE
	var initInteractionWithMouse = function()
	{
		self.invalidateClick		= false;
		self.timeMousePressed		= 0;


		/* NEW MOUSE INTERACTION */

		var onMouseDown = function(){

			self.timeMousePressed = new Date();
			self.invalidateClick = false;
			self.mousedown = true;
			self.mouseDownCoordinates = {x:self.mouse.x,y:self.mouse.y};
			self.previousMouseCoordinates = {x:self.mouse.x,y:self.mouse.y};
			renderer.domElement.style.cursor = "pointer";
		}
		var onMouseUp = function(){

			var elapsedTimeTmp = (new Date())-self.timeMousePressed;

			if(elapsedTimeTmp<150 && self.mouse.y<renderer.domElement.clientWidth/2)
			{
				onClick();
			}
			else
			{
				self.invalidateClick = true;
			}
			self.mousedown = false;
			renderer.domElement.style.cursor = "default";
		}
		var onMouseDrag = function(event) {
			var elapsedTimeTmp = (new Date())-self.timeMousePressed;

			self.mouse = {
				x: (event.clientX / renderer.domElement.clientWidth) * 2 - 1,
				y: -(event.clientY / renderer.domElement.clientHeight) * 2 + 1
			}

			if (elapsedTimeTmp>150 && self.mousedown && !self.cameraIsMoving)
			{
				var delta = mapRange(Math.abs(self.mouse.x-self.previousMouseCoordinates.x),0,1,0,degrees_to_radians( self.camera.fov));
				delta = delta * Math.sign(self.mouse.x-self.previousMouseCoordinates.x);
				self.previousMouseCoordinates = self.mouse;

				self.camera.rotateY(delta);
				renderer.domElement.style.cursor = "grabbing";
			}
		}
		var onMouseLeave = function(event)
		{
			var elapsedTimeTmp = (new Date())-self.timeMousePressed;
			if(self.mousedown && elapsedTimeTmp>150)
			{
				self.mousedown = false;
			}
			onMouseUp();
		}
		var onClick = function(event){

			if(!self.cameraIsMoving && self.doesIntersect.intersects)
			{

				self.cameraTarget = self.doesIntersect.target;
				if(self.cameraTarget == undefined || self.cameraTarget == null)
				{
					return;
				}
				self.cameraTarget.y = 10;
				self.cameraIsMoving = true;
				movementFactor = 0.0;
				startQuaternion = new THREE.Quaternion().copy(self.camera.quaternion);

				startCameraPos.x = self.camera.position.x;
				startCameraPos.y = self.camera.position.y;
				startCameraPos.z = self.camera.position.z;

				if(self.doesIntersect.targetRot == undefined)
				{

					/*******
					Going to a floor location
					******/

					var distTmp = self.camera.position.distanceTo(self.cameraTarget);
					var Y1 = self.cameraTarget.z;
					var X1 = self.cameraTarget.x;
					var Y2 = self.camera.position.z;
					var X2 = self.camera.position.x;
					//self.cameraRotationTarget = (Math.atan2(Y2-Y1,X2-X1) + (Math.PI/2));

					/*QUATERNION CALC*/
					self.camera.lookAt(new THREE.Vector3(self.cameraTarget.x,self.camera.position.y,self.cameraTarget.z));
					endQuaternion = new THREE.Quaternion().copy( self.camera.quaternion );
					self.camera.applyQuaternion(startQuaternion);
				}
				else
				{
					/*******
					Going to an artifact
					******/

					/*QUATERNION CALC*/
					self.camera.position.x = self.cameraTarget.x;
					self.camera.position.z = self.cameraTarget.z;

					self.camera.lookAt(new THREE.Vector3(self.doesIntersect.origin.x,self.camera.position.y,self.doesIntersect.origin.z));
					endQuaternion = new THREE.Quaternion().copy( self.camera.quaternion );
					self.camera.applyQuaternion(startQuaternion);
					self.camera.position.x = startCameraPos.x;
					self.camera.position.z = startCameraPos.z;

					/*Calculate DIStance, if close enough, open related Media*/
					var distTmp = self.cameraTarget.distanceTo(self.camera.position);
					if(distTmp<20)
					{
						window.setTimeout(function(){
							if(INTERSECTED.artifactLink!= null && INTERSECTED.artifactLink != undefined)
							{
								self.presentRelatedContent(INTERSECTED.artifactLink);
							}
						},100);
					}
				}
				if(self.isInTopCameraView)
				{
					self.animateCameraToNormalView();
				}
			}
		}

		renderer.domElement.addEventListener('mousemove', onMouseDrag);
		renderer.domElement.addEventListener('touchmove', onMouseDrag);

		renderer.domElement.addEventListener('touchstart',onMouseDown);
		renderer.domElement.addEventListener('mousedown',onMouseDown);

		renderer.domElement.addEventListener('mouseup',onMouseUp);
		renderer.domElement.addEventListener('touchend',onMouseUp);

		renderer.domElement.addEventListener('mouseleave',onMouseLeave);

		//ADD MARKER FOR MOUSE INTERACTION
		const geometry = new THREE.CylinderGeometry( 2, 2, 0.5, 32 );
		const material = new THREE.MeshLambertMaterial( { color:0xff0000 } );;
		const cylinder = new THREE.Mesh( geometry, material );
		self.line = cylinder;

		self.scene.add( self.line );
		self.line.rotateY(Math.PI/4);
	}
	var mouseUpdate = function(){

		if(self.cameraIsMoving)
		{
			movementFactor += (self.isInTopCameraView) ? 0.04 : 0.08;
			let expFactor = BezierBlend(movementFactor);
			self.camera.position.x = startCameraPos.x * (1-expFactor) + self.cameraTarget.x * expFactor;
			self.camera.position.y = startCameraPos.y * (1-expFactor) + self.cameraTarget.y * expFactor;
			self.camera.position.z = startCameraPos.z * (1-expFactor) + self.cameraTarget.z * expFactor;
		 THREE.Quaternion.slerp(startQuaternion, endQuaternion, self.camera.quaternion, expFactor);
			// self.camera.applyQuaternion( THREE.Quaternion.slerpQuaternions ( startQuaternion, endQuaternion, expFactor ));
			UIModel.fogDist = UIModel.fogDistStart * (1-expFactor) + UIModel.fogDistTarget *expFactor;

			if( movementFactor>=1.0)
			{
				self.cameraIsMoving = false;
				UIModel.fogDistStart = UIModel.fogDist;
			}else
			{

			}

		}
		else
		{
			mouseIntersect();
		}
	}
	var mouseIntersect = function()
	{
		if(self.mouse == undefined || self.camera == undefined)
		{
			return;
		}
		self.camera.updateMatrixWorld();

		//CONTENT INTERSECTION
		var raycasterTmp = new THREE.Raycaster();
		raycasterTmp.setFromCamera(self.mouse, self.camera);
		var intersects = raycasterTmp.intersectObjects(self.meshes,false);

		var isIntersecting = false;

		if (intersects.length > 0) {
			isIntersecting = true;

			const distTmp = 10;
			let angle = intersects[0].object.parent.rotation.z;


 			//CONVERT ANGLE BETWEEN 0 and TWO PI
 			let X1 = distTmp * Math.sin(Math.PI+angle) + intersects[0].object.parent.position.x;
 			let Z1 = distTmp * Math.cos(Math.PI+angle) + intersects[0].object.parent.position.z;
 			let X2 = distTmp * Math.sin(Math.PI+angle+Math.PI) + intersects[0].object.parent.position.x;
 			let Z2 = distTmp * Math.cos(Math.PI+angle+Math.PI) + intersects[0].object.parent.position.z;

 			//Check which is closer to camera.
 			let dist1 = self.camera.position.distanceTo(new THREE.Vector3(X1,self.camera.position.y,Z1));
 			let dist2 = self.camera.position.distanceTo(new THREE.Vector3(X2,self.camera.position.y,Z2));
 			var X,Z;

 			if(dist1>dist2)
 			{
 				X = X2;
 				Z = Z2;

 			}
 			else
 			{
 				X = X1;
 				Z = Z1;
 			}

 			self.doesIntersect = {target: new THREE.Vector3( X, self.camera.position.y, Z), targetRot:angle, origin:intersects[0].object.parent.position};

 			if ( INTERSECTED != intersects[ 0 ].object ) {

 				if ( INTERSECTED )
 				{
 					INTERSECTED.parent.children[0].material.emissive.setHex( INTERSECTED.currentHex );
 				}

 				INTERSECTED = intersects[ 0 ].object;

 				INTERSECTED.currentHex = INTERSECTED.parent.children[0].material.emissive.getHex();
 				INTERSECTED.parent.children[0].material.emissive.setHex( 0xff0000 );



 			}
 			var distToPosTmp = self.camera.position.distanceTo(intersects[0].object.parent.position);

 			if(!self.showRelatedContent && distToPosTmp<20 )
 			{

 				var artifactTmp = self.findArtifactWithXYZ(INTERSECTED.parent.position.x,INTERSECTED.parent.position.y,INTERSECTED.parent.position.z,self.artifacts);
					if(artifactTmp!=undefined)
					{
						if(artifactTmp,artifactTmp.relatedMedia!="")
						{
							self.hoverOverArtifact.show();
							INTERSECTED.artifactLink = artifactTmp;
							self.hoverOverArtifact.html("<div class = \"hoverInstructions\">Click again to see more!</div><div class='smallTitle'>"+artifactTmp.title+"</div><span class=\"small\">" + ((artifactTmp.author!="" || artifactTmp.author!=undefined)? "Author:"+artifactTmp.author : "" )+" Story:"+artifactTmp.story+"</span>");
						}
					}

				}

			}
			else {

				if ( INTERSECTED ){
					INTERSECTED.parent.children[0].material.emissive.setHex( INTERSECTED.currentHex );
				}

				INTERSECTED = null;
				self.hoverOverArtifact.hide();
			}
			if(!isIntersecting)
			{

	     		//FLOOR INTERSECTION
	     		intersects = raycasterTmp.intersectObjects([self.floor],false);

	     		if (intersects.length > 0) {

	     			for ( var i = 0; i < intersects.length; i++ ) {
	     				if(intersects[i].object.type == "Mesh" && intersects[i].face != null && intersects[i].object.name == "floor")
	     				{
	     					var distTmp = self.camera.position.distanceTo(intersects[i].point);

					        self.line.position.set(0, 0, 0);
					        self.line.position.copy(intersects[i].point);
					        self.doesIntersect = {target: new THREE.Vector3( intersects[i].point.x, self.camera.position.y,  intersects[i].point.z)};
					        let n = new THREE.Vector3();
					        isIntersecting = true;
					        break;
					    }
					}
				}
			}
			if(!isIntersecting)
			{
				self.doesIntersect.intersects    = false;
				renderer.domElement.style.cursor = "ew-resize";
				INTERSECTED = null;
			}
			else
			{
				self.doesIntersect.intersects 	 = true;
				renderer.domElement.style.cursor = "default";
			}
	}

	self.animateCameraToTopView = function()
	{
		self.isInTopCameraView =true;
		self.cameraIsMoving = true;
		self.cameraTarget.y = 400;
		self.cameraTarget.x = 150;
		self.cameraTarget.z = 200;
		startCameraPos.x = self.camera.position.x;
		startCameraPos.y = self.camera.position.y;
		startCameraPos.z = self.camera.position.z;

		UIModel.fogDistTarget = 820;
		UIModel.fogDistStart  = UIModel.fogDist;

		startQuaternion = new THREE.Quaternion().copy( self.camera.quaternion );
		self.camera.rotation.x = -Math.PI/2;
		self.camera.rotation.y = Math.PI/2;
		self.camera.rotation.z = -Math.PI/2;
		endQuaternion = new THREE.Quaternion().copy( self.camera.quaternion );
		self.camera.applyQuaternion(startQuaternion);

		movementFactor = 0.0;
		document.CollapsibleList.show();

		document.dispatchEvent(new Event('enteringTopCameraView', {}));


	}
	self.animateCameraToNormalView = function()
	{
		self.isInTopCameraView =false;
		UIModel.fogDistTarget = 250;
		UIModel.fogDistStart  = UIModel.fogDist;
		document.CollapsibleList.hide();
		self.hideDonutSelect();
		document.dispatchEvent(new Event('exitingTopCameraView', {}));
	}
	self.animateCameraToArtifact = function(artifact)
	{
		/*******
		Going to an artifact
		******/
		if(artifact.origin == undefined)
		{
			artifact = self.findArtifactWithName(artifact.title,self.artifacts);
		}

		self.cameraTarget.y = 10;
		self.cameraIsMoving = true;
		movementFactor = 0.0;

		//FIND TARGET
		const distToArtifact = 10;
		let angle = artifact.rot;

		//CONVERT ANGLE BETWEEN 0 and TWO PI
		let X1 = distToArtifact * Math.sin(Math.PI+angle) + artifact.x;
		let Z1 = distToArtifact * Math.cos(Math.PI+angle) + artifact.z;
		let X2 = distToArtifact * Math.sin(Math.PI+angle+Math.PI) + artifact.x;
		let Z2 = distToArtifact * Math.cos(Math.PI+angle+Math.PI) + artifact.z;

		//Check which is closer to camera.
		let dist1 = self.camera.position.distanceTo(new THREE.Vector3(X1,self.camera.position.y,Z1));
		let dist2 = self.camera.position.distanceTo(new THREE.Vector3(X2,self.camera.position.y,Z2));
		var X,Z;

		if(dist1>dist2)
		{
			X = X2;
			Z = Z2;

		}
		else
		{
			X = X1;
			Z = Z1;
		}

		//POSITION
		self.cameraIsMoving = true;
		self.cameraTarget.y = artifact.y;
		self.cameraTarget.x = X;
		self.cameraTarget.z = Z;

		startCameraPos.x = self.camera.position.x;
		startCameraPos.y = self.camera.position.y;
		startCameraPos.z = self.camera.position.z;

		//ROTATION
		startQuaternion = new THREE.Quaternion().copy( self.camera.quaternion );

		/*QUATERNION CALC*/
		self.camera.position.x = self.cameraTarget.x;
		self.camera.position.z = self.cameraTarget.z;
		self.camera.lookAt(new THREE.Vector3(artifact.x,self.camera.position.y,artifact.z));
		endQuaternion = new THREE.Quaternion().copy( self.camera.quaternion );
		self.camera.applyQuaternion(startQuaternion);

		self.camera.position.x = startCameraPos.x;
		self.camera.position.z = startCameraPos.z;

		self.animateCameraToNormalView();
	}
	self.animateCameraToNormalViewToPoint = function(targetLoc,targetItemToLookAt)
	{
		/*
			This method moves the camera from current location to a target location (targetLoc).
			The camera will face a specific item (targetItemToLookAt).
			targetLoc and targetItemToLookAt must be in the form:
			{"x":1.1,"y":2.0,"z":1.0}
		*/

		//POSITION
		self.cameraIsMoving = true;
		self.cameraTarget.y = targetLoc.y;
		self.cameraTarget.x = targetLoc.x;
		self.cameraTarget.z = targetLoc.z;

		startCameraPos.x = self.camera.position.x;
		startCameraPos.y = self.camera.position.y;
		startCameraPos.z = self.camera.position.z;

		//ROTATION
		startQuaternion = new THREE.Quaternion().copy( self.camera.quaternion );
		self.camera.lookAt(new THREE.Vector3(targetItemToLookAt.x,targetItemToLookAt.y,targetItemToLookAt.z));
		endQuaternion = new THREE.Quaternion().copy( self.camera.quaternion );
		self.camera.applyQuaternion(startQuaternion);

		movementFactor = 0.0;

		self.animateCameraToNormalView();

	}

	self.initStageVideo = function(){
		self.video = document.getElementById( 'stageVideo' );

		let vidTexture = new THREE.VideoTexture( self.video );
		vidTexture.minFilter = THREE.LinearFilter;
		vidTexture.magFilter = THREE.LinearFilter;
		vidTexture.format = THREE.RGBFormat;

		const parameters = { color: 0xffffff, map: vidTexture };

		//CREATE STAGE:
		var geometry = new THREE.BoxGeometry( 0.1, 80, 60 );
		var material = new THREE.MeshBasicMaterial( parameters );
		self.videoStage = new THREE.Mesh( geometry, material );
		// self.videoStage.needsUpdate = true;
		self.videoStage.position.x = -30;
		self.videoStage.position.y = 40;
		self.videoStage.position.z = 10;
		self.scene.add(self.videoStage);
	}

	// var loadYoutubePanel = function( id, x, y, z, ry ) {
	//
	// 			const div = document.createElement( 'div' );
	// 			div.style.width = '480px';
	// 			div.style.height = '360px';
	// 			div.style.backgroundColor = '#000';
	//
	// 			const iframe = document.createElement( 'iframe' );
	// 			iframe.style.width = '480px';
	// 			iframe.style.height = '360px';
	// 			iframe.style.border = '0px';
	// 			iframe.autoplay = "autoplay";
	// 			iframe.src = [ 'https://www.youtube.com/embed/', id, '?rel=0&controls=0' ].join( '' );
	// 			div.appendChild( iframe );
	//
	// 			const object = new CSS3DObject( div );
	// 			object.position.set( x, y, z );
	// 			object.rotation.y = ry;
	//
	// 			return object;
	//
	// 		};



	var loadArchitecture = function () {

		var wallWithNames = new WallWithNames();
		wallWithNames.create({ "x": 8.6, "y": -0.4, "z": -2.0 }, function (newObj) {
			self.scene.add(newObj);
		});

		var hallway = new Hallway();
		hallway.create({ "x": 10.0, "y":-0.49, "z": -3.0 }, function (newObj) {
			self.scene.add(newObj);
		});

		var ciidLogo = new CIIDLogo();
		ciidLogo.create({ "x": 3.0, "y":-0.4, "z": 13.0},function(newObj){
			self.scene.add(newObj);
		});




	}


	self.animate = function () {

		setTimeout(function () {
			requestAnimationFrame(self.animate);
		}, 1000 / 26);
		// requestAnimationFrame(self.animate);
		UIModel.animate();

		self.scene.background = new THREE.Color(UIModel.bgColor);//UIModel.bgColor );
		self.scene.fog = new THREE.Fog(UIModel.bgColor, 0, UIModel.fogDist);
		self.floor.material.color = new THREE.Color( 0xaaffff );

		if(self.pm != undefined)
		{
			//UPDATE PLAYER POSITION
			self.pm.updateMyPlayer();
		}

		if (self.controls.isLocked === true) {

			self.movementUpdate();

		}

		if (self.camera != undefined) {

			mouseUpdate();
			renderer.render(self.scene, self.camera);

			if(self.pm != undefined)
			{
				if(self.pm.myself != undefined)
				{
					self.pm.myself.position = self.camera.position;
				}
			}
		}
		// self.updateCloseObject();
	}
	self.updateCloseObject = function () {
		self.markerObjectProximity.style.display = 'none';
		for (var i = 0; i < self.artifacts.length; i++) {

			if (self.artifacts[i].obj == undefined) {
				break;
			}

			if (self.artifacts[i].obj.position.distanceTo(self.camera.position) < 15) {
				canJump = false;
				// self.artifacts[i].updateMatrixWorld();
				if (self.artifacts[i].relatedMedia != "") {
					if (self.showRelatedContent) {
						self.controls.unlock();
						$("#moiContainer").hide();
						$("#relatedMediaContainer").html(self.artifacts[i].relatedMedia);
						$("#relatedMediaContainer").show();
						self.showRelatedContent = false;
					}
					else {
						self.markerObjectProximity.style.display = 'block';
					}
				}
				break;
			}
		}
		if (self.showRelatedContent) {
			self.showRelatedContent = false;
		}

	}

	self.movementUpdate = function () {

		var time = performance.now();
		var delta = (time - prevTime) / 1000;

		velocity.x -= velocity.x * 10.0 * delta;
		velocity.z -= velocity.z * 10.0 * delta;

		velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

		direction.z = Number(moveForward) - Number(moveBackward);
		direction.x = Number(moveRight) - Number(moveLeft);
		direction.normalize(); // this ensures consistent movements in all directions

		if (moveForward || moveBackward) velocity.z -= direction.z * 400.0 * delta;
		if (moveLeft || moveRight) velocity.x -= direction.x * 400.0 * delta;

		if (onObject === true) {
			velocity.y = Math.max(0, velocity.y);
			canJump = true;
		}

		self.controls.moveRight(- velocity.x * delta);
		self.controls.moveForward(- velocity.z * delta);
		self.controls.getObject().position.y += (velocity.y * delta); // new behavior

		if (self.controls.getObject().position.y < 10) {
			velocity.y = 0;
			self.controls.getObject().position.y = 10;
			canJump = true;
		}
		prevTime = time;
	}

	self.createCurveBetweenTwoPoints = function(vector1,vector2)
	{
		let originP1 = new THREE.Vector3( vector1.x, vector1.y+6, vector1.z );
		let originP2 = new THREE.Vector3( vector2.x, vector2.y+6, vector2.z );
		let dist = originP1.distanceTo(originP2);

		let controlP1 = new THREE.Vector3(vector1.x*0.75 + vector2.x*0.25,Math.max(dist/3,originP1.y+6),vector1.z*0.75 + vector2.z*0.25);
				//Math.min(dist/10,originP2.y*4,100)
				let controlP2 = new THREE.Vector3(vector1.x*0.25 + vector2.x*0.75,Math.max(dist/3,originP2.y+6),vector1.z*0.25 + vector2.z*0.75);

				var curve = new THREE.CubicBezierCurve3(
					originP1,
					controlP1,
					controlP2,
					originP2
					);

				var points = curve.getPoints( 50 );

				var geometry = new THREE.BufferGeometry().setFromPoints( points );

				var material = new THREE.LineBasicMaterial( {
					color: 0xffaaaa,
					linewidth: 3.0

				} );
		// Create the final object to add to the scene
		var curveObject = new THREE.Line( geometry, material );

		return curveObject;
	}
	self.findArtifactWithName = function(name,list)
	{
		for(var i = 0;i<list.length;i++)
		{
			if(list[i].title == name)
			{
				return list[i];
			}
		}
	}
	self.findArtifactWithXYZ = function(x,y,z,list)
	{
		for(var i=0;i<list.length;i++)
		{

			var magnitude = Math.abs(list[i].x-x)+ Math.abs(list[i].y-y)+ Math.abs(list[i].z-z);

			if(Math.abs(magnitude)<10.0)
			{
				return list[i];
			}
		}
	}
	self.presentRelatedContent = function(artifact)
	{
		if(artifact!=undefined)
		{
			if (artifact.relatedMedia!=undefined) {
				self.controls.unlock();
				self.unlockControls();
				$("#moiContainer").hide();
				$("#relatedMediaContainer").html(artifact.relatedMedia);
				$("#relatedMediaContainer").show();
			}
		}
	}
	/* UTILS */
	const wrapToTwoPi = (angle) =>
	{
		if(angle<0)
		{
			angle = wrapToTwoPi(((Math.PI*2)+angle));
		}
		else if(angle>(Math.PI*2))
		{

			angle = wrapToTwoPi(angle-(Math.PI*2));
		}
		return angle;
	}
	const mapRange = (num, in_min, in_max, out_min, out_max) => {
		return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
	}
	const degrees_to_radians = (degrees) =>
	{
		return degrees * (Math.PI/180);
	}
	const radians_to_degrees = (radians) =>
	{
		return radians * (180/Math.PI);
	}
	const InOutQuadBlend = ( t)=>
	{
		if(t <= 0.5)
			return 2.0 * t * t;
		t -= 0.5;
		return 2.0 * t * (1.0 - t) + 0.5;
	}
	const  BezierBlend = ( t)=>
	{
		return t * t * (3.0 - 2.0 * t);
	}
	return self;
};

WorldBuilder.prototype = Object.create(EventDispatcher.prototype);
WorldBuilder.prototype.constructor = WorldBuilder;
export { WorldBuilder}
