import * as dat from 'dat.gui';
import * as THREE from 'three';

let UIModel = function()
{
	var self = {};
	self.bgColor 	= new THREE.Color( 0xEECDD1 );
	self.fogColor 	= [ 0, 0, 0, 0.3 ];
	self.lightColor = [ 0, 128, 255, 0.3 ];
	self.fogDist    = 250.0;
	self.fogDistTarget = 250.0;
	self.fogDistStart  = 250.0;

	self.colors 	= [new THREE.Color( 0xEECDD1 ),new THREE.Color( 0xE4FAA7 ),new THREE.Color( 0xF9E6A7 ),new THREE.Color( 0xCFE2E3 )];
	self.lerpingValue = Math.random()*3;

	self.animate = function(){
		//#3FC3DC
		self.lerpingValue += 0.00001;
		self.lerpingValue = self.lerpingValue%4;


		var intPart = Math.floor(self.lerpingValue);
		var floatPart = self.lerpingValue-intPart;
		var nextIndex = (intPart<(self.colors.length-1))?intPart+1:0;
		self.bgColor = self.colors[intPart].lerpHSL(self.colors[nextIndex],floatPart);

	}

	self.initGUI 	= function()
	{
		var gui = new dat.GUI();
 		gui.addColor(self, 'bgColor');
  		gui.addColor(self, 'fogColor');
  		gui.addColor(self, 'lightColor');
  		gui.add(self, 'fogDist',50.0,750.0);

	}
	return self;
}();



export {UIModel};
