import $ from 'jquery';

let CollapsibleList = function(artifacts){

	//GENERATE CONTENT
	var stories = {};
	for(var key in artifacts)
	{
		if(stories[artifacts[key].story]==undefined)
    	{
    		stories[artifacts[key].story] = [];
    	}
    	let tinyImage = $("<img src=\""+artifacts[key].contentUrl+"\" width=\"32px\" height=\"32px\"/>");
        let listItem = $("<div class=\"listItem\"></div>");
        listItem.append(tinyImage);
        listItem.append($("<span>"+artifacts[key].title+"</span>"));
        stories[artifacts[key].story].push(listItem);
        let pass = artifacts[key] ;
        listItem.on("mouseover", function(){

        	document.wb.showDonutSelect(pass.position.x*20,20,pass.position.z*20,);

        });
        listItem.on("click", function(){

        	//GO TO ARTIFACT
        	document.wb.animateCameraToArtifact(pass);
        	document.wb.hideDonutSelect();

        });
	}
	for(var title in stories)
	  {
	  	if(title == "other")
	  	{
	  		continue;
	  	}
	  	$("#searchBox").append($("<div style=\"padding:30px\" class=\"collapsible\">"+title+"</div>"));
	  	let container = $("<div class=\"listContent\"></div>");
	  	for(var elem in stories[title])
	  	{
	  		
	  		stories[title][elem].mouseover = function(){
	  			
	  		}
	  		container.append(stories[title][elem]);
	  		
	  	}
	  	$("#searchBox").append(container);
	  }


	//INTERACTIVITY
	var coll = document.getElementsByClassName("collapsible");
	var i;

	for (i = 0; i < coll.length; i++) {
	  coll[i].addEventListener("click", function() {
	    this.classList.toggle("listActive");
	    var content = this.nextElementSibling;
	    if (content.style.maxHeight){
	      content.style.maxHeight = null;
	    } else {
	      content.style.maxHeight = content.scrollHeight + "px";
	    } 
	  });
	}
	this.hide = function()
	{
		$("#searchBox").css('left','-275px');
	}
	this.show = function()
	{
		$("#searchBox").css('left','10px');
	}

}

export {CollapsibleList}