import firebase from "@firebase/app";
import "@firebase/firestore";

import { firebaseConfig, test } from "./utils";
import { WorldBuilder, Myself } from "./main/WorldBuilder";

import { HUD } from "./HUD.js";
import { CollapsibleList } from "./CollapsibleList.js";
import { generalUtils } from "./utils";
import { ChatRoom } from "./ChatRoom.js";
//
// import fs from "fs";
// import $ from 'jquery';

window.loadPageHelper = {};
window.loadPageHelper.dots = 0;
window.loadPageHelper.animateDots = function(){
  window.setTimeout(function(){
    if(!window.loadPageHelper.pageLoaded)
    {
       loadPageHelper.animateDots();
    }
    else
    {
      var elem = document.getElementById('loadingBox');
      elem.parentNode.removeChild(elem);
    }
  },200);
};
window.loadPageHelper.pageLoaded = false;
window.loadPageHelper.animateDots();

if(generalUtils.detectMobile())
{
  window.location.replace("/index_mobile.html");
}

var URL = window.URL || window.webkitURL;
if(window.URL == undefined)
{
  window.URL = window.webkitURL;
}
let readOffline = false;
// //::: OFFLINE ::::/
import {artifacts} from "./contentFromWS.js";

// //::: END OFFLINE ::::/
window.onload = function(){
  const main = () => {


    // //::: OFFLINE ::::/
    if(readOffline)
    {
      document.wb = new WorldBuilder();
      document.wb.init([], artifacts);
      document.wb.animate();

      document.HUD = new HUD();
      document.HUD.init();

      document.CollapsibleList = new CollapsibleList(artifacts);

      document.ChatRoom = new ChatRoom();

      window.loadPageHelper.pageLoaded = true;
      return;
    }
    // //::: END OFFLINE ::::/

    firebase.initializeApp(firebaseConfig);

    var db = firebase.firestore();

    document.wb = new WorldBuilder();
    var db = firebase.firestore();
    var returnObj = [];
    db.collection("map")
      .get()
      .then(function (querySnapshot) {

        querySnapshot.forEach(function (doc) {
           // console.log(JSON.stringify(doc.data().architecture));
          returnObj.push(JSON.stringify(doc.data().architecture));
        });
        var artifacts = [];
        db.collection("artifacts")
          .get()
          .then(function (querySnapshot) {


            querySnapshot.forEach(function (doc) {

              artifacts.push(doc.data());
            });
             console.log(JSON.stringify(artifacts));
            console.log(artifacts);
            document.wb.init(returnObj, artifacts);
            document.wb.animate();

            document.HUD = new HUD();
            document.HUD.init();

            document.CollapsibleList = new CollapsibleList(artifacts);

            document.ChatRoom = new ChatRoom();

            window.loadPageHelper.pageLoaded = true;

          });
      });

    db.collection("topography")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log(doc.id, " => ", doc.data());

          var node = document.createElement("li");
          var textnode = document.createTextNode(
            `${doc.data().title}, ${doc.data().description}, ${doc.data().year}`
          );
          node.appendChild(textnode);
          document.getElementById("artifacts").appendChild(node);
        });
      });


  };

  // Save content to firebase
  const onSubmit = e => {
    e.preventDefault();

    var db = firebase.firestore();

    const inputs = document.querySelector("form").elements;

    db.collection("artifacts")
      .doc(inputs["title"].value)
      .set({
        title: inputs["title"].value,
        description: inputs["description"].value,
        year: inputs["year"].value
      })
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  main();
};
