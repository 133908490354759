
import * as THREE from 'three';
import { UIModel } from "./UIModel.js";

let multi = 20;
var RoomBuilder = function(artifacts, callback){

	//group all the stories and find boundaries
	var stories = {};
	var indexes = {};
	var debug = {};

	self = this;
	self.boundaries = {};

	//GET THE NUMBER OF ITEMS IN A GALLERY
	for(var key in artifacts)
	{
		var storyName = artifacts[key].story;
		if(indexes[storyName] == undefined)
		{
			indexes[storyName] = 0;
		}
		indexes[storyName]++;
	}

	//CREATE ARRAY WHERE ALL ARTIFACTS ARE GROUBED BY STORY
	var absoluteXMax = -65535, absoluteZMax= -65535, absoluteXMin = 65535, absoluteZMin = 65535;
	for(var key in artifacts)
	{

		//CALCULATE ABSOLUTE MAX/MIN

		if(artifacts[key].position.x-0>absoluteXMax)
		{
			absoluteXMax = artifacts[key].position.x-0;
		}
		else if(artifacts[key].position.x-0<absoluteXMin)
		{
			absoluteXMin = artifacts[key].position.x-0;
		}


		if(artifacts[key].position.z-0>absoluteZMax)
		{
			absoluteZMax = artifacts[key].position.z-0;
		}
		else if(artifacts[key].position.z-0<absoluteZMin)
		{
			absoluteZMin = artifacts[key].position.z-0;
		}

		// POPULATE STORY ARRAY
		var storyName = artifacts[key].story;
		if(storyName == "null" || storyName == null || indexes[storyName]<3 || storyName == "other")
		{
			continue;
		}
		if(stories[storyName] == undefined)
		{
			debug[storyName] = [];
			stories[storyName] = {xmax:-65535,xmin:65535,ymax:-65535,ymin:65535};
		}


		debug[storyName].push(artifacts[key].position);

		//CALCULATE STORIES MIN AND MAX
		if(stories[storyName].xmax < artifacts[key].position.x-0)
		{
			stories[storyName].xmax = artifacts[key].position.x-0;
		}
		if(stories[storyName].xmin>artifacts[key].position.x-0)
		{
			stories[storyName].xmin = artifacts[key].position.x-0;
		}


		if(stories[storyName].ymax<artifacts[key].position.z-0)
		{
			stories[storyName].ymax = artifacts[key].position.z-0;
		}
		if(stories[storyName].ymin>artifacts[key].position.z-0)
		{
			stories[storyName].ymin = artifacts[key].position.z-0;
		}

	}


	//CREATE ROOMS FOR EVERY STORY
	for(var key in stories)
	{

		var storyTmp = stories[key];
		var xMinMulti = storyTmp.xmin * multi - 20;
		var yMinMulti = storyTmp.ymin * multi - 20;
		var xMaxMulti = storyTmp.xmax * multi + 40;
		var yMaxMulti = storyTmp.ymax * multi + 20;


		let points = [];

		/* DOME */
		if(indexes[key]<9)
		{
			const numRibs = Math.random()*15 + 40;
			var heightMinTmp =  50;
			var heightMaxTmp =  60+Math.random()*20 ;
			var xDist = xMaxMulti - xMinMulti;
			for(var i = 0;i<numRibs;i++)
			{
				var indNorm = 1.0 - (i/numRibs);
				let originP1 	= new THREE.Vector3( xMinMulti + indNorm*xDist, -0.5, yMinMulti );
				let originP2 	= new THREE.Vector3( xMinMulti + indNorm*xDist,-0.5, yMaxMulti );
				let controlP1 	= new THREE.Vector3( xMinMulti + indNorm*xDist, heightMinTmp + (1-indNorm)*heightMaxTmp, yMinMulti );
				let controlP2 	= new THREE.Vector3( xMinMulti + indNorm*xDist, heightMinTmp + (1-indNorm)*heightMaxTmp, yMaxMulti );


				var curve = new THREE.CubicBezierCurve3(
					originP1,
					controlP1,
					controlP2,
					originP2
					);
				var pointsTmp = curve.getPoints( 10 );
				for(var a in pointsTmp)
				{
					points.push(pointsTmp[a]);
				}

			}

		}
		/* HALF BARREL */
		else if (indexes[key]<12 && indexes[key]>=9)
		{
			var heightTmp = Math.random()*20 + 40;
			const numRibs = Math.random()*30 + 20;
			var xDist = xMaxMulti - xMinMulti;
			for(var i = 0;i<numRibs;i+=2)
			{

				var indNorm = (i/numRibs);
				points.push(new THREE.Vector3(xMinMulti  ,indNorm*heightTmp, yMinMulti ));
				points.push(new THREE.Vector3(xMinMulti  ,indNorm*heightTmp, yMaxMulti ));
				points.push(new THREE.Vector3(xMaxMulti  ,indNorm*heightTmp, yMaxMulti ));
				points.push(new THREE.Vector3(xMaxMulti  ,indNorm*heightTmp, yMinMulti ));

				points.push(new THREE.Vector3(xMaxMulti  ,((i+1)/numRibs)*heightTmp, yMinMulti ));
				points.push(new THREE.Vector3(xMaxMulti  ,((i+1)/numRibs)*heightTmp, yMaxMulti ));
				points.push(new THREE.Vector3(xMinMulti  ,((i+1)/numRibs)*heightTmp, yMaxMulti ));
				points.push(new THREE.Vector3(xMinMulti  ,((i+1)/numRibs)*heightTmp, yMinMulti ));

			}
		}
		/* CAGE */
		else
		{
			var heightTmp = Math.random()*20 + 55;
			const numRibs = 40;
			var yDist = yMaxMulti - yMinMulti;
			for(var i = 0;i<numRibs;i++)
			{

				var indNorm =  (i/numRibs);
				points.push(new THREE.Vector3(xMinMulti  , -1, yMinMulti + indNorm*yDist ));
				points.push(new THREE.Vector3(xMinMulti , heightTmp, yMinMulti + indNorm*yDist ));
				points.push(new THREE.Vector3(xMaxMulti , heightTmp, yMinMulti + indNorm*yDist));
				points.push(new THREE.Vector3(xMaxMulti , -1, yMinMulti + indNorm*yDist));
			}
		}
		if(points.length>0)
		{
			/* LINE WORK */
			let geometry = new THREE.BufferGeometry().setFromPoints( points );
			var objTmp = new THREE.Line( geometry,  new THREE.LineBasicMaterial( { color: 0x717171,linewidth: 1.0 } ) );

			/* FLOOR */
			// const geometryFloor = new THREE.BufferGeometry();

			// const vertices = new Float32Array( [
			// 	xMinMulti,  0.05, yMinMulti,
			// 	xMinMulti,  0.05, yMaxMulti,
			// 	xMaxMulti,  0.05, yMaxMulti,
			//
			// 	xMaxMulti,  0.05, yMaxMulti,
			// 	xMaxMulti,  0.05, yMinMulti,
			// 	xMinMulti,  0.05, yMinMulti
			// ] );
			// const vertices = new Float32Array( [
			// 	xMinMulti,  9, yMinMulti,
			// 	xMinMulti,  9, yMaxMulti,
			// 	xMaxMulti,  9, yMaxMulti,
			//
			// 	xMaxMulti,  15, yMaxMulti,
			// 	xMaxMulti,  15, yMinMulti,
			// 	xMinMulti,  15, yMinMulti
			// ] );
			//
			// geometryFloor.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
			// var newColor = UIModel.bgColor;
			// const material = new THREE.MeshPhongMaterial({
			// 					    color:newColor,
			// 					    opacity: 0.3,
			// 					    transparent: true
			// 					  });
			//
			// const material = new THREE.MeshLambertMaterial( { color: 0x799b9e } );
			//  const material = new THREE.MeshLambertMaterial({color: 0xffffff});
			//  // const material = new THREE.MeshBasicMaterial({color:0xffffff});
			// const meshFloor = new THREE.Mesh( geometryFloor, material );
			// meshFloor.receiveShadow = true;
			// meshFloor.castShadow = false;
			//
			// objTmp.add(meshFloor);

			//Create a plane that receives shadows (but does not cast them)
			let sizeX = xMinMulti - xMaxMulti;
			let sizeY = yMinMulti - yMaxMulti;
			let originX = xMinMulti - (sizeX/2);
			let originY = yMinMulti - (sizeY/2);
			const planeGeometry = new THREE.PlaneGeometry( sizeX, sizeY, 28, 24 );
			const planeMaterial = new THREE.MeshLambertMaterial( { color: 0xffffaa,wireframe: true } )
			const plane = new THREE.Mesh( planeGeometry, planeMaterial );
			plane.position.set(originX,0.05,originY);
			plane.rotation.set(Math.PI/2,Math.PI,0);
			plane.receiveShadow = true;
			objTmp.add( plane );


			callback(objTmp);
		}
	}

	//CREATE CONTAINER FOR THE WHOLE EXHIBITION
	var externalCageHeight = 10;
	const extCageRibs = 2;
	var points = [];

	var offset = 50;

	absoluteXMin = absoluteXMin*multi - offset;
	absoluteXMax = absoluteXMax*multi + offset;


	absoluteZMin = absoluteZMin*multi - offset;
	absoluteZMin = (absoluteZMin > -100 ) ? -100 : absoluteZMin;
	absoluteZMax = absoluteZMax*multi + offset;

	for(var i = 0;i<extCageRibs;i++)
	{
		var indNorm =  (i/extCageRibs)+0.3;
		points.push(new THREE.Vector3(absoluteXMin , externalCageHeight*indNorm, absoluteZMin ));
		points.push(new THREE.Vector3(absoluteXMax , externalCageHeight*indNorm, absoluteZMin ));
		points.push(new THREE.Vector3(absoluteXMax , externalCageHeight*indNorm, absoluteZMax));
		points.push(new THREE.Vector3(absoluteXMin , externalCageHeight*indNorm, absoluteZMax));
		points.push(new THREE.Vector3(absoluteXMin , externalCageHeight*indNorm, absoluteZMin ));
	}

	self.globalBoundaries = {"xMin":absoluteXMin,"xMax":absoluteXMax,"yMin":absoluteZMin,"yMax":absoluteZMax};

	/* LINE WORK */
	let geometryExternalCage = new THREE.BufferGeometry().setFromPoints( points );
	var externalCage = new THREE.Line( geometryExternalCage,  new THREE.LineBasicMaterial( { color: 0x717171,linewidth: 1.0 } ) );
	callback(externalCage);
	return self;
}

export {RoomBuilder};
