
import * as THREE from 'three';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { GLTFLoader } from '../shared/CustomGLTFLoader.js';

import {
	Euler,
	EventDispatcher,
	Vector3
} from "./three.module.js";

var CIIDLogo = function(){
	let self = this;
	self.url = "";
	self.x = -1;
	self.y = -1;
	self.z = -1;
	self.obj;

	self.create = function (position, callback) {

		//ADD POSITION
		self.x = position.x * 20;
		self.y = position.y * 20 + 10;
		self.z = position.z * 20;

		//CREATE MATERIAL

		//LOAD IMAGE
		var texMaterial = new THREE.MeshBasicMaterial( { color: 0xffffff, wireframe:true } );;

		//LOAD 3D model
		var loader = new GLTFLoader();
		let finishLoadingCallback = callback;

		loader.load( "https://museumofinteractivity.github.io/moi-src/3dModels/CIIDLogo.glb", function ( gltf ) {

			let objTmp = new THREE.Object3D( );

			//Material
			setTimeout(function(){
				// if(gltf.scene.children[0].material != undefined)
				// {
				// 	gltf.scene.children[0].material = texMaterial;
				// }
				// gltf.scene.children[0].material = new THREE.MeshLambertMaterial({ color: 0xffffff, wireframe:true });
				gltf.scene.children[0].material = new THREE.MeshBasicMaterial({ color: 0x000000, wireframe:true });
				objTmp.add( gltf.scene.children[0] );

				objTmp.rotation.set	(0,0,0);
				objTmp.position.set	(self.x, self.y, self.z);

				self.obj = objTmp;
				finishLoadingCallback(  objTmp );

			},750);
		}, undefined, function ( error ) {
			console.error( "error",error );
		} );
	}
}

var CIIDSignage = function(){
	let self = this;
	self.url = "";
	self.x = -1;
	self.y = -1;
	self.z = -1;
	self.obj;

	self.create = function (position, callback) {

		//ADD POSITION
		self.x = position.x * 20;
		self.y = position.y * 20 + 10;
		self.z = position.z * 20;

		//CREATE MATERIAL

		//LOAD IMAGE
		self.url = '/shared/Signage.jpg';
		var texMaterial;
		var texMap;
		var texture = new THREE.TextureLoader().load( self.url,function ( map ) {
				    texMap = map;
				    texMaterial = new THREE.MeshBasicMaterial( { map: texture } );
				});


		//LOAD 3D model
		var loader = new GLTFLoader();
		let finishLoadingCallback = callback;

		loader.load( '/shared/Signage.glb', function ( gltf ) {

			let objTmp = new THREE.Object3D( );

			//Material
			setTimeout(function(){
				if(gltf.scene.children[0].material != undefined)
				{
					gltf.scene.children[0].material = texMaterial;
					if(gltf.scene.children[0].material.map != undefined)
					{
						gltf.scene.children[0].material.map = texMap;
					    gltf.scene.children[0].material.map.encoding = THREE.sRGBEncoding;
					    gltf.scene.children[0].material.map.flipY = false;
				    }
				    gltf.scene.children[0].material.needsUpdate = true;
				}

				objTmp.add( gltf.scene.children[0] );

				objTmp.scale.set	(3.0,3.0,3.0);

				objTmp.rotation.set	(0,0,0);
				objTmp.position.set	(self.x, self.y, self.z);

				self.obj = objTmp;
				finishLoadingCallback(  objTmp );

			},750);
		}, undefined, function ( error ) {
			console.error( "error",error );

		} );
	}
}
var CIIDTitle = function () {

	let self = this;
	self.url = "";
	self.x = -1;
	self.y = -1;
	self.z = -1;
	self.obj;

	self.create = function (position, callback) {

		//ADD POSITION
		self.x = position.x * 20;
		self.y = position.y * 20 + 10;
		self.z = position.z * 20;

		//CRETE MATERIAL

		//LOAD IMAGE
		self.url = '/shared/CIIDTitle.jpg';
		var texMaterial;
		var texture = new THREE.TextureLoader().load( self.url );
		texMaterial = new THREE.MeshBasicMaterial( { map: texture } );

		//LOAD 3D model
		var loader = new GLTFLoader();
		let finishLoadingCallback = callback;

		loader.load( '/shared/CIIDTitle.glb', function ( gltf ) {

			let objTmp = new THREE.Object3D( );

			//Material
			setTimeout(function(){

				gltf.scene.children[0].material = texMaterial;

				objTmp.add( gltf.scene.children[0] );

				objTmp.scale.set	(4.0,4.0,4.0);

				objTmp.rotation.set	(Math.PI/2,0,-Math.PI/2);
				objTmp.position.set	(self.x, self.y, self.z);

				self.obj = objTmp;

				finishLoadingCallback(  objTmp );
			},100);




		}, undefined, function ( error ) {

			console.error( "error",error );

		} );
	}
}
var CIIDExhibit = function () {

	let self = this;
	self.url = "";
	self.x = -1;
	self.y = -1;
	self.z = -1;
	self.obj;

	self.create = function (position, callback) {

		//ADD POSITION
		self.x = position.x * 20;
		self.y = position.y * 20 + 10;
		self.z = position.z * 20;

		//CRETE MATERIAL

		var colorMaterial = new THREE.MeshPhongMaterial({"color":0xAAAAAA,"shininess": 30});

		//LOAD 3D model
		var loader = new GLTFLoader();
		let finishLoadingCallback = callback;

		loader.load( '/shared/CIIDExhibit.glb', function ( gltf ) {

			let objTmp = new THREE.Object3D( );

			//Material
			setTimeout(function(){
				for(var a = 0;a< gltf.scene.children.length;a++)
				{

					gltf.scene.children[a].material = colorMaterial;


					objTmp.add( gltf.scene.children[a] );
				}
				for(var a = 0;a< gltf.scene.children.length;a++)
				{

					gltf.scene.children[a].material = colorMaterial;


					objTmp.add( gltf.scene.children[a] );
				}
				for(var a = 0;a< gltf.scene.children.length;a++)
				{

					gltf.scene.children[a].material = colorMaterial;


					objTmp.add( gltf.scene.children[a] );
				}

				// objTmp.add( gltf.scene.children[1] );


				objTmp.scale.set	(4.0,4.0,4.0);

				// objTmp.rotation.set	(-Math.PI/2,0,self.rot);
				objTmp.position.set	(self.x, self.y, self.z);

				self.obj = objTmp;

				finishLoadingCallback(  objTmp );
			},100);




		}, undefined, function ( error ) {

			console.error( "error",error );

		} );
	}
}



export { CIIDExhibit, CIIDTitle, CIIDSignage, CIIDLogo }
