
import * as THREE from 'three';
import * as DOMPurify from 'dompurify';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { GLTFLoader } from '../shared/CustomGLTFLoader.js';

import {
	Euler,
	EventDispatcher,
	Vector3
} from "./three.module.js";
import {PlainAnimator} from '../shared/PlainAnimator.js';



var Frame = function(){
	let yearColor = {"1972":0xF7B297,"1984":0xF07382,"1996":0xBF6D86,"2001":0x5B4E68,"2020":0x355E7D}
	let self = this;
	self.url = "";
	self.x   = -1;
	self.y   = -1;
	self.z   = -1;
	self.obj;
	self.author = "";
	self.type = "None";

	let IMAGE 		= "Image";
	let THREEDMODEL = "3dModel";
	let GIF 		= "GIF";

	self.relatedMedia = '';

	self.create = function(newArtifact,callback)
	{

		self = Object.assign(self, newArtifact);

		if(self.type == THREEDMODEL)
		{
			self.is3dModel = (newArtifact.contentUrl.indexOf(".glb") != -1) || (newArtifact.contentUrl.indexOf(".gltf")!=-1);
			if(!self.is3dModel)
			{
				console.error("ERROR LOADING: "+newArtifact.name+"\n url is not a 3d model (.glb) \t"+newArtifact.contentUrl);
			}

		}
		//https://github.com/museumofinteractivity/moi-src/blob/gh-pages/3dModels/Frame.glb?raw=true


		//ADD POSITION
		self.x = newArtifact.position.x*20;
		self.y = newArtifact.position.y*20 + (self.is3dModel) ? 0 : 10;
		self.z = newArtifact.position.z*20;

		self.rot = (newArtifact.rotation != undefined)? ((newArtifact.rotation-0) * (Math.PI/180)):0;

		self.author = newArtifact.author;


		//LOAD IMAGE
		var loadTextureImage = newArtifact.contentUrl;
		if(self.type == THREEDMODEL)
		{
			if(newArtifact.contentUrl.indexOf(".glb")!=-1)
			{
				loadTextureImage = loadTextureImage.replace(".glb",".png");
			}
			if(newArtifact.contentUrl.indexOf(".gltf")!=-1)
			{
				loadTextureImage = loadTextureImage.replace(".gltf",".png");
			}
			loadTextureImage = "https://museumofinteractivity.github.io/moi-src/imgs/olpc.jpg";
		}


		self.url = newArtifact.contentUrl;
		var texMaterial;
		var texture = new THREE.TextureLoader().load( loadTextureImage );
		texMaterial = new THREE.MeshBasicMaterial( { map: texture } );

		//OTHER
		self.age = newArtifact.age;
		self.story = newArtifact.story;
		if(newArtifact.relatedMedia!= undefined)
		{
			self.relatedMedia = newArtifact.relatedMedia.content;
		}

		self.title = newArtifact.title;

		//LOAD 3D model
		var loader = new GLTFLoader();
		let finishLoadingCallback = callback;
		var loadGLTFURL = '/shared/Frame.glb';
		if(self.is3dModel){
			loadGLTFURL = newArtifact.contentUrl+"?raw=true";
		};


		loader.load( loadGLTFURL , function ( gltf ) {

			let objTmp = new THREE.Object3D( );

			if(!self.is3dModel)
			{
				//Just Frame
				var col = yearColor[self.age];
				if(col == undefined)
				{
					col = 0x777777;
				}
				var picMat =  new THREE.MeshStandardMaterial( { color:col } );
				gltf.scene.children[1].material = picMat;
				gltf.scene.children[1].castShadow = true;
				objTmp.add( gltf.scene.children[1] );

				//Content
				if(self.type == GIF)
				{
					self.animator =  new  PlainAnimator(texture, 4, 4, self.numFrames, 5);
					const spriteTexture = self.animator.init();
					gltf.scene.children[0].material = texMaterial;
					self.animate();
				}
				else if(self.type == IMAGE)
				{
					gltf.scene.children[0].material = texMaterial;
				}

				objTmp.scale.set	(5.0,5.0,5.0);
				objTmp.rotation.set	(-Math.PI/2,0,self.rot);
				// }
			}
			else
			{
				objTmp.rotation.set	(0,self.rot,0);
			}
			// gltf.scene.children[0].castShadow = true;
			objTmp.add( gltf.scene.children[0] );





			objTmp.position.set	(self.x, self.y, self.z);

			objTmp.name = newArtifact.name;
      self.obj = objTmp;

			finishLoadingCallback(  objTmp );



		}, undefined, function ( error ) {

			console.warn( "error" , error );

		} );

	}

	self.animate = function()
	{
		if(self.type == "GIF")
		{
			setTimeout(function () {
				requestAnimationFrame(self.animate);
			}, 1000 / 26);
			self.animator.animate();
		}
	}


	self.convertVector = function(inputVector)
	{
		var newVec = {};
		newVec.x = inputVector.x*20;
		newVec.y = inputVector.y*20 +10;
		newVec.z = inputVector.z*20;
		return newVec;
	}

}

export {Frame}
