import { FriendlyWebSocket } from '../wsConnection.js';


class WSManager
{
	constructor(props) {
		Object.assign(this, props);


		var url = "wss://" + location.hostname + ":8080";

		this.connection = new FriendlyWebSocket()

		this.clientIDCache = -1;

		this.connection.onopen = () => {
			//...
		}

		this.connection.onerror = error => {
			console.log(`WebSocket error: ${error}`)
		}
		this.connection.on('message', data => {

			if (typeof data === "string") {
				//create a JSON object]
				var jsonObject = JSON.parse(data);
				const { event, message, value } = jsonObject;
				if (event === 'INIT_PLAYER')
				{
					if(jsonObject.value != undefined)
					{
						if(jsonObject.value.clientID != undefined)
						{
							this.clientIDCache = value.clientID;
							let self = this;
							var updateClientID = function()
							{
								if(self.clientIDReceived == undefined)
								{
									window.setTimeout(updateClientID,1000);
								}
								else
								{
									self.clientIDReceived(self.clientIDCache);
								}
							}
							updateClientID();
						}
					}
				}
				else if(event === 'CLIENT_CONNECTED')
				{

					if(this.visitorConnected != undefined)
					{
						this.visitorConnected(value.clientID);
					}
				}
				else if(event === 'CLIENT_DISCONNECTED')
				{
					if(this.visitorDisconnected != undefined)
					{
						this.visitorDisconnected(value.clientID);
					}
				}
				else if(event === 'PLAYERS_POS')
				{
					if(this.playersUpdateCallback != undefined)
					{
						this.playersUpdateCallback(value);
					}
				}
				else if(event === 'CHAT_MSG')
				{
					if(this.chatCallback!=undefined)
					{
						this.chatCallback(jsonObject);
					}
				}
				else if(event === 'DONE_SAVE')
				{
					if(this.saveCallback!=undefined)
					{
						this.saveCallback(jsonObject);
					}
				}
				else if(event === 'AVAILABLE_READ')
				{

					if(this.availableReadCallback != undefined)
					{
						this.availableReadCallback(jsonObject.value);
					}
				}

			}
		});
	};
	isConnected = () =>
	{
		if(this.connection != undefined)
		{
			if(this.connection.connected)
			{
				return true;
			}
		}
		else
		{
			return false;
		}
		return false;
	}
	subscribeClienIDReceived = (callback) =>
	{
		if(typeof callback == 'function')
		{
			this.clientIDReceived = callback;
		}
	}
	subscribeVisitorConnected = (callback) =>
	{
		if(typeof callback == 'function')
		{
			this.visitorConnected = callback;
		}
	}
	subscribeVisitorDisconnected = (callback) =>
	{

		if(typeof callback == 'function')
		{
			this.visitorDisconnected = callback;
		}
	}
	subscribeChatListener = (callback) =>
	{
		if(typeof callback == 'function')
		{
			this.chatCallback = callback;
		}
	}
	subscribePlayersUpdateListener = (callback) =>
	{
		if(typeof callback == 'function')
		{
			this.playersUpdateCallback = callback;
		}
	}
	subscribeSave = (callback) =>
	{
		if(typeof callback == 'function')
		{
			this.saveCallback = callback;
		}
	}
	subscribeAvailableRead = (callback) =>
	{
		if(typeof callback == 'function')
		{
			this.availableReadCallback = callback;
		}
	}
	sendOpenConnectionMessage = (msg) => {
		if (this.connection != undefined) {
			const message = {
				'event': 'OPEN_MSG',
				'value': msg,
				'sender':'client'
			}
			if (this.connection.connected) {
				this.connection.send(JSON.stringify(message));
			};
		}
	}
	sendChatMessage = (msg) => {
		if (this.connection != undefined) {
			const message = {
				'event': 'CHAT_SND',
				'value': msg,
				'sender':'client'
			}
			if (this.connection.connected) {
				this.connection.send(JSON.stringify(message));
			};
		}
	}
	sendGenericMessage = (event,msg) => {
		if (this.connection != undefined) {
			const message = {
				'event': event,
				'value': msg,
				'sender':'client'
			}
			if (this.connection.connected) {
				this.connection.send(JSON.stringify(message));
			};
		}
	}
	updatePlayerPosition = (player) => {
		if (this.connection != undefined)
		{

			let newValue = {};
			newValue.x = player.position.x;
			newValue.y = player.position.y;
			newValue.z = player.position.z;
			newValue.name = player.name;
			newValue.rot = document.wb.camera.rotation.y;

			const message = {
				"event" : "UPDATE_PLAYER",
				"value" : newValue,
				"sender": "client"
			}

			if(this.connection.connected)
			{
				this.connection.send(JSON.stringify(message));
			}
		}
	}

}

export {WSManager}
