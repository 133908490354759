import * as THREE from 'three';



var SoundTrack = function (listener, position, callback ) {
  // create the PositionalAudio object (passing in the listener)
  var st = this;
  //const sound = new THREE.PositionalAudio( listener );

  // load a sound and set it as the PositionalAudio object's buffer
  const audioLoader = new THREE.AudioLoader();
  audioLoader.load( 'https://museumofinteractivity.github.io/moi-src/soundtrack/basetrack.ogg', function( buffer ) {
    if(sound == undefined)
    {
      var sound = new THREE.PositionalAudio( listener );
    }
  	sound.setBuffer( buffer );
  	sound.setRefDistance( 20 );
    sound.setLoop(true);
  	sound.play();

    // create an object for the sound to play from
    position.x = position.x * 20;
    position.y = position.y * 20 + 10;
    position.z = position.z * 20;

    const sphere = new THREE.SphereGeometry(4, 4, 4 );

    const material = new THREE.MeshPhongMaterial( { color: 0xff2200 } );
    const mesh = new THREE.Mesh( sphere, material );
    mesh.position.set( position.x, position.y, position.z );
    mesh.scale.set(0.01,0.01,0.01);
    // scene.add( mesh );

    // finally add the sound to the mesh
    mesh.add( sound );

    callback(mesh);
    st.sound = sound;
  });

  st.pause = function()
  {
    if(st.sound != undefined)
    {
      st.sound.pause();
    }
    else {
      return "error, no soundtrack";
    }

  }

  st.resume = function()
  {
    if(st.sound != undefined)
    {
      st.sound.play();
    }
  }
  return st;
}


export { SoundTrack }
