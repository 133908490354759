
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import {
	Euler,
	EventDispatcher,
	Vector3
} from "./three.module.js";



var Hallway = function () {

	let self = this;
	self.url = "";
	self.x = -1;
	self.y = -1;
	self.z = -1;
	self.obj;

	self.create = function (position, callback) {

		//ADD POSITION
		self.x = position.x * 20;
		self.y = position.y * 20 + 10;
		self.z = position.z * 20;

		//LOAD IMAGE
		self.url = "./shared/wood-texture.jpg";
		var woodMaterial;
		var texture = new THREE.TextureLoader().load(self.url);
		woodMaterial = new THREE.MeshBasicMaterial({ map: texture });

		self.colorMaterial = new THREE.MeshPhongMaterial({"color":0xFFAAAA,"shininess": 30});

		//LOAD 3D model
		var loader = new GLTFLoader();
		let finishLoadingCallback = callback;


		loader.load( '/shared/hallwayEntrance.glb', function ( gltf ) {

			let objTmp = new THREE.Object3D( );

			//Just outside
			// var picMat = new THREE.MeshPhongMaterial({"color":0xE7BB9D,"shininess": 30});
			// gltf.scene.children[1].material = picMat;
			// objTmp.add( gltf.scene.children[1] );

			//Material Textured

			gltf.scene.children[0].material = woodMaterial;

			// gltf.scene.children[1].material = self.colorMaterial;

			objTmp.add( gltf.scene.children[0] );

			// objTmp.add( gltf.scene.children[1] );


			objTmp.scale.set	(25.0,25.0,25.0);

			// objTmp.rotation.set	(-Math.PI/2,0,self.rot);
			objTmp.position.set	(self.x, self.y, self.z);


			finishLoadingCallback(  objTmp );

			self.obj = objTmp;

		}, undefined, function ( error ) {

			console.error( "error",error );

		} );
	}
}
export { Hallway }
